@use 'variables/colors' as var;

html {
  height: 100%;
  box-sizing: border-box;
}

body {
  position: relative;
  display: block;
  width: 100%;
  min-height: 100%;
  font-family: 'Montserrat', 'Tahoma', sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  color: var.$colorWhite;
  background-color: var.$colorDark;
  overflow-x: hidden;
  //--ion-safe-area-top: env(safe-area-inset-top);
  --ion-safe-area-bottom: env(safe-area-inset-bottom);
}

*, *:before, *:after {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

b {
  font-weight: 700;
}

img {
  display: block;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.roulette, .roulette * {
  user-select: none;
}

.container {
  position: relative;
  width: 100%;
  //max-width: 575px;
  margin: 0 auto;
  //padding: 0 16px;

  &--flex-center {
    min-height: 100%;
    display: flex;
    align-items: center;
  }
}

.wrapper {
  //position: fixed;
  //left: 0;
  //top: 0;
  //right: 0;
  //bottom: 0;
  //width: 100%;
  //height: 100%;
  //overflow: auto;
  //display: flex;
  //flex-direction: column;
  ////max-width: 575px;
  ////max-width: 360px;
  //margin: 0 auto;
  padding: 8px 16px 16px;
  overflow-x: hidden;
  display: block;
  width: 100%;
  height: 100%;
}

$colorDark: #140132;
$colorDark8: rgba(20, 1, 50, 0.8);
$colorWhite: #ffffff;
$colorBlack: #000000;
$colorBlack25: rgba(0, 0, 0, 0.25);

$colorViolet: #6800BA;
$colorVioletLight: #AB9BFD;
$colorCyan: #01FFC2;
//$dark: #140132;
//$dark-light: #080132;
//$dark5: rgba(20, 1, 50, 0.5);
//$dark7: rgba(20, 1, 50, 0.7);
//$light-dark: #1E0A46;
//$cyan: #01FFC2;
//$pink: #FA05F1;
//$pink7: rgba(250, 5, 241, 0.7);
//$blue: #9F92E1;
//$blue7: rgba(159, 146, 225, 0.7);
//$light-blue: #F1EFFB;
//$error: #FA05F1;
//$green: #00FF47;
//$orange: #FFA800;
//$brown: #C39481;
//$yellow: #F2CC05;
//$red: #FF0E2B;
//$light-green: #00FF00;
//$light-violet: #A056FF;
//$neon-light: #01FFC280;
//$bg-light: #F8F0FF;
//$orange: #FF6B00;

$gradient1: linear-gradient(104.33deg, rgba(72, 10, 138, 0.8) 0%, rgba(38, 10, 80, 0.8) 62.87%, rgba(50, 11, 122, 0.8) 113.42%);
$gradient2: linear-gradient(91.72deg, #6800BA -0.18%, #7156FB 49.32%, #01FFC2 99.82%);
$gradient3: linear-gradient(180deg, #140132 100%, rgba(104, 0, 186, 0.1) 20%, rgba(113, 86, 251, 0.1) 100%);
$gradient4: linear-gradient(90deg, #6206F9 0%, #AB9BFD 100%);
$gradient5: linear-gradient(90deg, #AB9BFD 0%, #6206F9 100%);
$gradient6: linear-gradient(180deg, rgba(72, 10, 138, 0) 0%, rgba(38, 10, 80, 0.8) 41.61%, #320B7A 100%);
$gradient7: linear-gradient(180deg, rgba(104, 0, 186, 0.49) 16.02%, rgba(48, 5, 96, 0.35) 59.46%, rgba(20, 1, 50, 0.7) 98.93%);
$gradient8: linear-gradient(90deg, #AB9BFD 0%, #6206F9 100%);
$transition: .2s ease;


